<template>
	<div ref="interface" id="interface" class="interface" :class='{"hidden":!isFormShown}'>
		<form id="form" class="form" @submit.prevent="findLocation()">
			<div class="form_group">
				<input ref="input" class="form_input" type="text" placeholder="Entrer votre adresse postale" tabindex="1" v-model="input">
				<button class="form_button form_submit" type="submit" title="Rechercher" :tabindex="2">
					<svg xmlns="http://www.w3.org/2000/svg" width="24.219" height="24.223" viewBox="0 0 24.219 24.223"><path class="a" d="M9.84,3.785A6.055,6.055,0,1,1,5.558,5.558,6.055,6.055,0,0,1,9.84,3.785M9.84,0a9.84,9.84,0,1,0,9.84,9.84A9.839,9.839,0,0,0,9.84,0Z"/><path class="b" d="M343.99,342.656l-1.339,1.339a1.131,1.131,0,0,1-1.6,0l-4.716-4.716a1.136,1.136,0,0,1-.331-.8V337.7l1.7-1.7h.771a1.136,1.136,0,0,1,.8.331l4.716,4.716A1.141,1.141,0,0,1,343.99,342.656Z" transform="translate(-320.105 -320.105)"/></svg>
				</button>
				<div class="form_separator"></div>
				<button class="form_button form_geolocate" type="button" name="locate-me" title="Géolocalisez-moi" :tabindex="3" @click="geolocate"><span class="form_geolocate-text">ou me localiser</span><svg xmlns="http://www.w3.org/2000/svg" width="24.223" height="24.223" viewBox="0 0 24.223 24.223"><path class="a" d="M176.327,172.163A4.163,4.163,0,1,1,172.163,168,4.163,4.163,0,0,1,176.327,172.163Z" transform="translate(-160.052 -160.052)"/><path class="b" d="M23.466,10.6H21.059a9.077,9.077,0,0,0-7.433-7.433V.757A.757.757,0,0,0,12.869,0H11.355A.757.757,0,0,0,10.6.757V3.164A9.077,9.077,0,0,0,3.164,10.6H.757A.757.757,0,0,0,0,11.355v1.514a.757.757,0,0,0,.757.757H3.164A9.077,9.077,0,0,0,10.6,21.059v2.407a.757.757,0,0,0,.757.757h1.514a.757.757,0,0,0,.757-.757V21.059a9.077,9.077,0,0,0,7.433-7.433h2.407a.757.757,0,0,0,.757-.757V11.355A.757.757,0,0,0,23.466,10.6Zm-11.355,7.57a6.056,6.056,0,1,1,6.056-6.056A6.056,6.056,0,0,1,12.112,18.167Z"/></svg></button>
				<div class="form_separator"></div>
				<button class="form_button form_filters" type="button" name="show-filters" title="Afficher les filtres" :tabindex="4" @click="classes.filters.isHide=!classes.filters.isHide"><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="layer-group" class="svg-inline--fa fa-layer-group fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g class="fa-group"><path class="fa-secondary" fill="#00A0B6" d="M12.41 236.31L70.51 210l161.63 73.27a57.64 57.64 0 0 0 47.72 0L441.5 210l58.09 26.33c16.55 7.5 16.55 32.5 0 40L266.64 381.9a25.68 25.68 0 0 1-21.29 0L12.41 276.31c-16.55-7.5-16.55-32.5 0-40z" opacity="0.4"></path><path class="fa-primary" fill="#00A0B6" d="M12.41 148l232.94 105.7a25.61 25.61 0 0 0 21.29 0L499.58 148c16.55-7.51 16.55-32.52 0-40L266.65 2.32a25.61 25.61 0 0 0-21.29 0L12.41 108c-16.55 7.5-16.55 32.52 0 40zm487.18 216.11l-57.87-26.23-161.86 73.37a57.64 57.64 0 0 1-47.72 0L70.29 337.88l-57.88 26.23c-16.55 7.5-16.55 32.5 0 40L245.35 509.7a25.68 25.68 0 0 0 21.29 0l233-105.59c16.5-7.5 16.5-32.5-.05-40z"></path></g></svg></button>
			</div>
			<p class="form_tip">Si vous ne trouvez pas votre adresse dans cette recherche, entrez une adresse voisine.</p>
		</form>
		<div class="filters" :class="{ hide: classes.filters.isHide }">
			<span class="filters_label">Filtres :</span>
			<ul class="filters_list">
				<li class="filters_item" v-for="(layer, layer_idx) in layers.slice(0, 3)" :key="`layer-${layer_idx + 1}`">
					<input :id="`layer-${layer_idx + 1}`" class="" type="checkbox" :name="`layer-${layer_idx + 1}`" :tabindex="5 + layer_idx" @click="updateLayers(layer)" v-model="layer.visible">
					<label class="" :for="`layer-${layer_idx + 1}`" v-html="layer.name"></label>
				</li>
			</ul>
		</div>
		<div class="interface_maj">
			<p v-if="last_upload">Carte et données mises à jour le {{new Date(last_upload.time).toLocaleDateString()}}</p>
		</div>

		<div class="hide-button" @click="toggleForm()">
			<svg v-if="isFormShown" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-double-left" class="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M224 239l135.61-136a23.78 23.78 0 0 1 33.8 0L416 125.64a23.94 23.94 0 0 1 0 33.89l-96.16 96.37L416 352.27a23.94 23.94 0 0 1 0 33.89L393.53 409a23.78 23.78 0 0 1-33.8 0L224.12 273a23.94 23.94 0 0 1-.1-34z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M32.11 239l135.55-136a23.77 23.77 0 0 1 33.79 0L224 125.74a23.94 23.94 0 0 1 0 33.89L127.89 256l96 96.47a23.94 23.94 0 0 1 0 33.89L201.35 409a23.77 23.77 0 0 1-33.79 0L32 273a24 24 0 0 1 .11-34z"></path></g></svg>
			<svg v-else xmlns="http://www.w3.org/2000/svg" width="24.219" height="24.223" viewBox="0 0 24.219 24.223"><path class="a" d="M9.84,3.785A6.055,6.055,0,1,1,5.558,5.558,6.055,6.055,0,0,1,9.84,3.785M9.84,0a9.84,9.84,0,1,0,9.84,9.84A9.839,9.839,0,0,0,9.84,0Z"/><path class="b" d="M343.99,342.656l-1.339,1.339a1.131,1.131,0,0,1-1.6,0l-4.716-4.716a1.136,1.136,0,0,1-.331-.8V337.7l1.7-1.7h.771a1.136,1.136,0,0,1,.8.331l4.716,4.716A1.141,1.141,0,0,1,343.99,342.656Z" transform="translate(-320.105 -320.105)"/></svg>
		</div>
	</div>
</template>

<script>
export default {
	name: "Form",
	props: {
		apiKey: {
			type: String,
			default: () => null
		},
		map: {
			type: Object,
			default: () => null
		},
		layers: {
			type: Array,
			default: () => null
		},
		bounds: {
			type: Object,
			default: () => null
		},
		isFormShown: {
			type: Boolean,
			default: () => null
		}
	},
	emits: [
		"updatePosition",
		"updateName",
		"updateLayers",
		"updateFormShown"
	],
	data () {
		return {
			service: null,
			input: "",
			last_upload: null,
			classes: {
				filters: {
					isHide: true
				}
			},
			location: null,
			query: {
				types: "(cities)",
				fields: [
					"name",
					"formatted_address",
					"geometry"
				]
			}
		};
	},
	watch: {
		map: function () {
			this.service = new window.google.maps.places.PlacesService(this.map);
		}
	},
	mounted () {
		this.$refs.input.focus();
		this.loadLastUpload();
	},
	methods: {
		loadLastUpload () {
			this.$axios.get(this.$serverURL + "/last_upload")
				.then((results) => {
					this.last_upload = results.data;
				});
		},
		toggleForm (e) {
			this.$emit("updateFormShown", !this.isFormShown);
		},
		updateLayers (layer) {
			// N'active qu'une seule limite a la fois
			if (layer.visible) {
				return;
			}
			var layers = this.layers;
			switch (layer.id) {
			case "plaques":
				layers[0].visible = true;
				layers[1].visible = false;
				break;
			case "communes":
				layers[2].visible = false;
				break;
			}
			this.$emit("updateLayers", layers);
		},
		callbackFindPlaceFromQuery (results, status) {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				this.location = {
					name: results[0].formatted_address,
					location: results[0].geometry.location
				};
				this.submitForm();
			} else if (status === window.google.maps.places.PlacesServiceStatus.NOT_FOUND ||
				status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
				alert("Aucune commune n'a été trouvée.");
			} else {
				alert(status);
			}
		},
		findLocation () {
			if (this.input.length < 1) {
				alert("Veuillez entrer un code postal ou le nom d'une commune.");
			} else {
				const options = {
					query: this.input,
					locationBias: this.bounds,
					fields: this.query.fields
				};
				this.service.findPlaceFromQuery(options, this.callbackFindPlaceFromQuery);
			}
		},
		submitForm () {
			this.input = this.location.name;
			this.$emit("updateFormShown", false);
			this.$emit("updatePosition", this.location.location);
			this.$emit("updateName", this.location.name);
		},
		geolocate () {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((pos) => {
					this.$emit("updatePosition", new window.google.maps.LatLng(pos.coords.latitude, pos.coords.longitude));
					this.$emit("updateFormShown", false);
				});
			}
		}
	}
};
</script>

<style scoped lang="scss">
.interface {
	position: fixed;
	top: #{$navHeight};
	background-color: #ffffff;
	font-size: 1.4rem;
	color: #707070;
	transition: transform 0.25s ease-out;
	&.hidden{
		transform: translateX(calc(-100% - 10px));
	}
	.hide-button
	{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		box-shadow: 0px 5px 10px #00000029;
		cursor: pointer;
		border-radius: 48px;
		margin-left : 16px;

		svg {
			width: 24px;
			height: 24px;
			.a, .fa-primary {
				fill:#00a0b6;
			}

			.b, .fa-secondary {
				fill:#71c5d0;
			}
		}

	}

	.form,
	.filters {
		border-bottom: 2px solid #ebebeb;
	}

	.form {
		position: relative;
		padding: .6rem 1.6rem;
		border-radius: 3px 3px 0 0;

		&_group {
			display: grid;
			grid-template-columns: minmax(100px, 1fr) repeat(5, auto);
			grid-gap: 0;
		}

		&_input,
		&_geolocate {
			color: #707070;
		}

		&_input {
			vertical-align: top;
			position: relative;
			z-index:8;
			height: 100%;
			padding: 8px 12px;
			border: none;
			border-radius:3px;
			border:2px solid transparent;
			transition: all ease-out 0.3s;
			color:#00a0b6;
			font-weight:600;
			&:focus{
				border-color: #00a0b6;
				outline:none;
				outline-width: 0;
			}
			&::placeholder {
				font-weight:400;
				color: #222222;
			}
		}

		&_button {
			vertical-align: top;
			display: inline-block;
			background-color: #ffffff;
			border: none;
			cursor: pointer;
			svg {
				vertical-align: middle;
				width: 24px;
				height: 100%;
			}

			&:hover,
			&:focus {
				background-color: #f0f0f0;
			}

			&:active {
				background-color: #e0e0e0;
			}
		}

		&_submit {
			width: 32px;
			height: 32px;
			padding: 0 .4rem;
			border-radius: 3px;

			svg {
				.a {
					fill:#00a0b6;
				}

				.b {
					fill:#71c5d0;
				}
			}
		}

		&_geolocate {
			height: 32px;
			border-radius: 3px;

			&-text {
				margin-right: .8rem;
			}

			svg {
				.a {
					fill:#71c5d0;
				}

				.b {
					fill:#00a0b6;
				}
			}
		}

		&_filters {
			display: block;
			width: 32px;
			height: 32px;
			padding: 0 .4rem;
			border-radius: 3px;
		}

		&_tip {
			margin: .4rem 0;
			font-style: italic;
		}

		&_separator {
			vertical-align: middle;
			display: inline-block;
			width: 2px;
			height: 32px;
			margin: 0 8px;
			background-color: #ebebeb;
		}
	}

	.filters {
		opacity: 1;
		overflow: hidden;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 0 3rem;
		align-items: center;
		max-height: 300px;
		padding: .8rem 1.6rem;
		transition: max-height 600ms ease, opacity 400ms ease;
		&_label {
			vertical-align: middle;
			font-weight: 700;
			display:none;
		}

		&_list {
			display: grid;
			padding: 0;
			margin: 0;
			list-style: none;
		}

		&_item {
			position: relative;
			margin-bottom:0.5rem;
			input[type=checkbox] {
				position: absolute;
				width:0;
				height:0;
				opacity:0;
				visibility: hidden;
				margin-right: .8rem;
				&:checked + label{
					&:before{
						box-shadow: inset 0 0 0 1px white, inset 0 0 0 6px #005E6B;
					}
				}
			}
			label{
				display:flex;
				cursor:pointer;
				&:before{
					display:block;
					content:"";
					width:12px;
					height:12px;
					position: relative;
					top:2px;
					flex-shrink: 0;
					border-radius:3px;
					border:2px solid #005E6B;
					margin-right:0.8rem;
					transition:all ease-out 0.3s;
				}
			}
		}
	}

	&_maj {
		display: none;
		padding: .8rem 1.6rem;
		font-size:1.2rem;
		font-style: italic;
		font-weight:600;
	}
}

@media screen and (max-width: $desktop - 1) {
	.interface {
		.hide-button
		{
			top:8px;
			right:-60px;
			width:40px;
			height:40px;
		}

		.form {
			&_geolocate-text {
				display: none;
			}
		}

		.filters.hide {
			opacity: 0;
			max-height: 0;
			padding: 0;
			border: none;
			transition: max-height 600ms ease, opacity 400ms ease, padding 100ms 400ms ease, border 100ms 400ms ease;
		}
	}
}

@media screen and (min-width: $desktop) {
	.interface {
		top: calc(#{$navHeight} + 10px);
		left: 10px;
		border-radius: 1rem;
		box-shadow: 0px 5px 10px #00000029;

		&_maj{
			display: block;
		}

		.form {
			&_separator:nth-child(5),
			&_filters {
				display: none;
			}
		}
	}
	.hide-button{
		top: 0;
		right: -60px;
		width: 48px;
		height: 48px;
	}
}
</style>
