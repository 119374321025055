<template>
	<div class="home" :class="{ loading: isLoading }">
		<div class="header">
			<div class="header_left">
				<a class="header_button" href="https://www.smn76.fr/">
					<svg
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="long-arrow-left"
						class="svg-inline--fa fa-long-arrow-left fa-w-14"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<g class="fa-group">
							<path
								class="fa-secondary"
								fill="currentColor"
								d="M128.09 220H424a24 24 0 0 1 24 24v24a24 24 0 0 1-24 24H128.09l-35.66-36z"
								opacity="0.4"
							></path>
							<path
								class="fa-primary"
								fill="currentColor"
								d="M142.56 409L7 273.5v-.06a25.23 25.23 0 0 1 0-34.84l.06-.06 135.5-135.49a24 24 0 0 1 33.94 0l17 17a24 24 0 0 1 0 33.94L92.43 256 193.5 358a24 24 0 0 1 0 33.94l-17 17a24 24 0 0 1-33.94.06z"
							></path>
						</g>
					</svg>
					<span>www.smn76.fr</span>
				</a>
				<img
					class="header_logo"
					src="../assets/images/logo-smn76.png"
					alt="Logo SMN76"
					width="148"
					height="82"
				/>
			</div>
			<div class="header_right">
				<h1 class="header_title">
					Avancement des travaux de la fibre 2017-2023
				</h1>
				<p class="title-desc">
					Découvrez si le Très Haut Débit est disponible sur votre
					secteur
				</p>
			</div>
		</div>
		<Map
			:apiKey="apiKey"
			:layers="layers"
			:bounds="bounds"
			:position="position"
			:name="name"
			@updateMap="updateMap($event)"
			@updateLoading="updateLoading($event)"
			@updateFormShown="updateFormShown($event)"
		/>
		<Form
			:apiKey="apiKey"
			:map="map"
			:layers="layers"
			:bounds="bounds"
			:isFormShown="isFormShown"
			@updatePosition="updatePosition($event)"
			@updateName="updateName($event)"
			@updateLayers="updateLayers($event)"
			@updateFormShown="updateFormShown($event)"
		/>
		<div class="legend" :class="{ hidden: !isFormShown }">
			<ul>
				<li>Études en cours</li>
				<li>Travaux en cours</li>
				<li>Disponible sous 3 mois</li>
				<li>Commercialisation en cours</li>
				<li>Zones non couvertes par Seine-Maritime Numérique</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Map from "@/components/Map.vue";
import Form from "@/components/Form.vue";

export default {
	name: "Home",
	components: {
		Map,
		Form
	},
	data () {
		return {
			apiKey: "AIzaSyDPCxEYGX3we_oizJhIRu4yQbsKI1nrCP8",
			map: null,
			layers: [
				{
					id: "avancement",
					name:
						"Etat d’avancement de la fibre (études, travaux, commercialisation)",
					url: this.$serverURL + "/geojson",
					visible: true
				},
				{
					id: "communes",
					name: "Limites des communes",
					url: "./assets/geojson/communes.geojson",
					visible: false
				},
				{
					id: "plaques",
					name:
						"Limites des plaques techniques de distribution de la fibre",
					url: undefined,
					visible: false
				},
				{
					id: "contour",
					name: "",
					url: "./assets/geojson/contour.geojson",
					visible: true
				}
			],
			bounds: {
				north: 50.57,
				south: 49.17,
				east: 2.36,
				west: -1.37
			},
			position: { lat: 49.6792572, lng: 1.0471768 },
			name: "",
			isLoading: true,
			isFormShown: true
		};
	},
	methods: {
		updateMap (map) {
			this.map = map;
		},
		updatePosition (position) {
			this.position = position;
		},
		updateName (name) {
			this.name = name;
		},
		updateLayers (layers) {
			this.layers = layers;
		},
		updateLoading (state) {
			this.isLoading = state;
		},
		updateFormShown (state) {
			this.isFormShown = state;
		}
	}
};
</script>

<style lang="scss">
@keyframes spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.home {
	width: 100%;
	height: 100%;

	&.loading {
		&::before,
		&::after {
			content: "";
			position: absolute;
			z-index: 999;
			display: block;
		}

		&::before {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(255, 255, 255, 0.7);
		}

		&::after {
			box-sizing: border-box;
			width: 50px;
			height: 50px;
			top: 50%;
			left: 50%;
			margin-top: -10px;
			margin-left: -10px;
			border: 5px solid #71c5d0;
			border-top-color: #00a0b6;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			transform-origin: center;
			animation: spinner 1s linear infinite;
		}
	}
}
</style>

<style scoped lang="scss">
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: 100%;
	height: #{$navHeight};
	padding: 1rem;
	&_left {
		display: flex;
		align-items: center;
		height: 100%;
	}
	&_right {
		text-align: right;
	}
	&_logo {
		height: calc(#{$navHeight} - 1.6rem);
	}
	&_title {
		margin-bottom: 0;
	}
	&_button {
		display: inline-flex;
		align-items: center;
		color: #00a0b6;
		height: 100%;
		line-height: 1em;
		font-size: 1.4rem;
		font-weight: 600;
		text-decoration: none;
		margin-right: 1rem;
		padding-right: 1rem;
		border-right: 2px solid #71c5d0;
		transition: all ease-out 0.3s;
		svg {
			width: 16px;
			height: 16px;
			margin-right: 0.5rem;
		}
		span {
			white-space: nowrap;
		}
		&:visited {
			color: #00a0b6;
		}

		&:hover,
		&:active,
		&:focus {
			border-color: #71c5d0;
			color: #71c5d0;
		}
	}
}

.title-desc {
	display: none;
	margin: 0;
	font-weight: 600;
}

.legend {
	position: absolute;
	bottom: 10px;
	left: 10px;
	padding: 1.2rem 1.5rem;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: 0px 5px 10px #00000029;
	font-size: 1.3rem;
	color: #707070;
	transition: transform 0.3s ease-out;
	&.hidden {
		transform: translateX(calc(-100% - 10px));
	}
	ul {
		padding-left: 24px;
		margin: 0;
		list-style: none;
		li {
			position: relative;
			margin-bottom: 0.2rem;
			&::before {
				content: "";
				position: absolute;
				display: block;
				width: 18px;
				height: 12px;
				top: 3px;
				left: -24px;
				border-radius: 3px;
			}

			@each $index, $color in $colorsLayers {
				&:nth-child(#{$index})::before {
					background-color: $color;
				}
			}
		}
	}
}

@media screen and (max-width: $desktop - 1) {
	.header {
		&_title,
		&_button {
			font-size: 1.2rem;
		}
		&_left {
			flex: 0 0 60%;
		}
	}
	.title-desc {
		font-size: 1.2rem;
	}
}

@media screen and (min-width: $tabletPortrait) {
	.header {
		padding: 1rem 1.6rem;
		&_button {
			margin-right: 2rem;
		}
	}
}

@media screen and (min-width: $tabletLandscape) {
	.title-desc {
		display: block;
	}
}

@media screen and (min-width: $desktop) {
	.header {
		height: #{$navHeight};
		&_logo {
			height: calc(#{$navHeight} - 1.6rem);
		}
	}
	.title-desc {
		font-size: 1.6rem;
	}
}

@media screen and (min-width: $desktopBig) {
}
</style>
