import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "normalize.css";

/**
 * Retourne l'url de l'api en fonction de l'environnement du serveur.
 */
function getApiUrl () {
	// Si une valeur est passée par variable d'environnement, alors on utilise celle la
	if (process.env.API_URL) {
		return process.env.API_URL;
	}

	// Si on est en prod, on utilise l'api de prod. Sinon on utilise celle de dev
	if (process.env.NODE_ENV === "prod" || process.env.NODE_ENV === "production") {
		return "https://prod.seinemaritimenumerique.smnu.production-webadn.com:42421";
	} else {
		// return "https://dev.seinemaritimenumerique.smnu.production-webadn.com:3007";
		return "https://dev.seinemaritimenumerique.smnu.production-webadn.com:3007";
	}
}

const app = createApp(App);
app.use(router);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$serverURL = getApiUrl();
app.mount("#app");
